(function() { 'use strict';

  // Const selectors
  // ---------------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');

  // Helpers
  // -------
  window.WINDOW_HEIGHT = $WINDOW.height();
  window.HEADER_HEIGHT = $HEADER.height();


  // Masked input
  // ------------
  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');


  // Hover elements
  // --------------
  if( IS_MOBILE ) {
    var $menus = $('.js-menu'),
        $with_popup = $('.js-with-popup');

    $menus.off('click.js-menu');
    $with_popup.off('click');

    $menus.on('click.js-menu', 'span', function(e) {
      e.preventDefault();

      var $anchor = $(this).closest('a'),
          $parent = $(this).closest('li'),

          isWithDropdown = $parent.hasClass('with-dropdown'),
          isOnHover = $parent.hasClass('is-hovered');

      $parent.siblings('.with-dropdown').removeClass('is-hovered').children('ul').first().slideUp(350);

      if (!isWithDropdown) {
        if ($anchor.length > 0)
          location.href = $anchor.attr('href');
      } else {
        if (isOnHover) {
          if ($anchor.length > 0)
            location.href = $anchor.attr('href');
          else
            $parent.removeClass('is-hovered').children('ul').first().slideUp(350);
        } else {
          $parent.addClass('is-hovered').children('ul').first().slideDown(350);
        }
      }
    });

    $with_popup.on('click', function(e) {
      e.preventDefault();

      var $self = $(this);

      if( $self.hasClass('is-hovered') ) {
          $self.removeClass('is-hovered').children('.js-popup').slideUp(350);
      } else {
        var $hoverSiblings = $self.siblings('.is-hovered');

        if( $hoverSiblings.length > 0 ) {
          $hoverSiblings.removeClass('is-hovered').children('.js-popup').slideUp(350, function() {
            $self.addClass('is-hovered').children('.js-popup').slideDown(350);
          });
        } else {
          $self.addClass('is-hovered').children('.js-popup').slideDown(350);
        }
      }
    });
  }


  // Slick sliders
  // -------------
  var slickSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    customPaging: function() { return $('<div />'); },
    draggable: false
  };

  $('#header-rotator').slick($.extend({}, slickSettings, {
    centerMode: true,
    centerPadding: '0px',
    appendArrows: '#header-rotator-controls',
    slide: '.header-rotator-slide-container'
  }));

  $('#partner-rotator').slick($.extend({}, slickSettings, {
    appendArrows: '#partner-rotator-controls',
    slide: '.partner-rotator-slide-container',
    slidesToShow: 5
  }));


  // Scroll to
  // ---------
  $DOCUMENT.on('click', '.js-scroll-to', function(e) {
    e.preventDefault();

    var $lnk = $(this),
        $elemToScroll = $($lnk.attr('href').substring($lnk.attr('href').lastIndexOf('#'))),
        speed = $lnk.data('scrollSpeed') || 150,
        offset = $lnk.data('scrollOffset') || 0;

    $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
  });


  // Scrolling to top
  // ----------------
  if( !IS_MOBILE ) {
    var goTopBtn = $('#go-top-button');

    goTopBtn.click(function() {
      $WINDOW.scrollTo(0, 200);
    });

    $WINDOW.on('scroll', function(e, goTopBtnClass) {
      var scrollTop = $WINDOW.scrollTop();

      if ( goTopBtnClass == undefined )
        goTopBtnClass = 'top-button-visible';

      if ( scrollTop >= HEADER_HEIGHT ) {
        goTopBtn.addClass(goTopBtnClass);
      } else {
        goTopBtn.removeClass();
      }
    }).trigger('scroll', ['top-button-custom-visible']);
  }


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    margin: 0,

    infobar: true,
    buttons: true,

    slideShow : false,
    fullScreen: false,
    thumbs: false,
    closeBtn: true,

    smallBtn: false,

    image : {
      protect : true
    },

    focus: true,
    focusElement: '.js-autofocus-inp'
  });

  $('[data-fancybox]').fancybox();

})();